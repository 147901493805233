.container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Great Vibes', cursive;
  font-size: 500%;
}

@media only screen and (max-width: 600px) {
  .centered {
    font-size: 200%;
  }
}

section {
  padding-bottom: 15px;
}